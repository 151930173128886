(function iife(){
  function CustomScrollTo() {
    if (!(this instanceof CustomScrollTo)) return new CustomScrollTo();

    this.isScrolling = false;
    this.scroll = window.pageYOffset;
    this.dampScroll = window.pageYOffset;
  }

  /**
   * Scroll To Handler
   * @param {String} link to querySelector
   */
  CustomScrollTo.prototype.init = function(element, speed, onEndCallback) {
    if (element === null) {
      return false;
    }

    var sizes = element.getBoundingClientRect();
    var max = document.body.offsetHeight - window.innerHeight;
    var top = sizes.top + window.pageYOffset;

    // Make sure to not scroll more than the max scroll allowed
    if (top >= max) {
      top = max;
    }

    // Start the scroll
    this.start(top, speed, onEndCallback);
  }

  /**
   * Handler for the wheel event
   * Is used to cancel the programmatic scroll animation
   */
  CustomScrollTo.prototype.wheelHandler = function(event) {
    if (event.deltaY > 30 && this.isScrolling) {
      this.isScrolling = false;
    }
  }

  /**
   * Start the scroll animation
   * @param {Number} target The target scroll
   */
  CustomScrollTo.prototype.start = function(target, speed, onEndCallback) {
    // Update vars
    this.isScrolling = true;
    this.dampScroll = window.pageYOffset;
    this.scroll = target;

    // Bind wheel event to stop the animation if
    // the user wants to scroll manually
    window.addEventListener("wheel", this.wheelHandler.bind(this), {
      passive: true
    });

    // Init the loop
    return this.loop(speed, onEndCallback);
  }

  /**
   * Scroll animation end's hook
   */
  CustomScrollTo.prototype.end = function(onEndCallback) {
    window.removeEventListener("wheel", this.wheelHandler);

    if (onEndCallback !== undefined) {
      onEndCallback();
    }
  }

  /**
   * Scroll animation's loop
   * @return {Function} loop
   */
  CustomScrollTo.prototype.loop = function(speed, onEndCallback) {
    if (!this.isScrolling) {
      return this.end(onEndCallback);
    }

    this.dampScroll = this.damp(this.scroll, this.dampScroll, speed);
    window.scrollTo({ top: this.dampScroll });

    if (this.dampScroll === this.scroll) {
      this.isScrolling = false;
    }

    return requestAnimationFrame(this.loop.bind(this, speed, onEndCallback));
  }

  /**
   * Smoothly update a value to reach the given target
   * */
  // eslint-disable-next-line class-methods-use-this
  CustomScrollTo.prototype.damp = function(target, current, speed) {
    var speed = speed === undefined ? 0.08 : speed;
    var value = current + (target - current) * speed;
    return Math.abs(target - current) < 0.1 ? target : value;
  }

  window.customScrollTo = new CustomScrollTo();
})(window, document);
